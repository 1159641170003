@use 'mixins';

%-wins-dots {
    width: 3em;
    text-align: center;
    white-space: nowrap;

    .tag {
        margin-right: calc(var(--tinyPadding) / 2);
        margin-left: calc(var(--tinyPadding) / 2);
    }
}

.WinsBlock {
    display: block;
    margin-top: var(--standardPadding);

    [data-theme='vi'] & {
        margin-top: 0;
    }

    &.home {
        text-align: right;

        [data-theme='vi'] & {
            @extend %-wins-dots;

            margin-left: auto;
        }
    }

    &.away {
        text-align: left;

        [data-theme='vi'] & {
            @extend %-wins-dots;

            margin-right: auto;
        }
    }

    &.home,
    &.away {
        [data-theme='vi'] & {
            min-width: 3em;
            margin-top: var(--smallPadding);
            text-align: center;
        }
    }

    &.match-of-the-day {
        margin-top: 0;
        text-align: center;
    }

    .tag {
        display: inline-block;
        width: 1.125em;
        height: 1.125em;
        margin-right: var(--standardRadius);
        padding: 0;
        border-radius: var(--standardRadius);

        [data-theme='vi'] & {
            width: 0.5em;
            height: 0.5em;
            margin-right: var(--tinyPadding);
            border-radius: 50%;
        }

        &:global(.positive) {
            background-color: rgb(var(--positiveColor));
        }

        &:global(.negative) {
            background-color: rgb(var(--negativeColor));
        }

        &:global(.neutral) {
            background-color: rgb(var(--sectionSeparationBorder));
        }
    }
}
