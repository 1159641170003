.TeamSetup {
    .shirt {
        display: block;
        width: 1em;
        height: 1em;

        svg {
            width: 1.25em;
            height: 1.25em;
        }
    }

    .events {
        :global(.Icon) {
            width: 1.3em;
            height: 1.3em;
        }

        /* stylelint-disable selector-class-pattern */
        :global(.switchIn-icon) {
            margin-top: 0.15em;
        }

        :global(.secondYellowCard-icon),
        :global(.yellowCard-icon),
        :global(.redCard-icon) {
            margin-top: 0.1em;
        }
        /* stylelint-enable selector-class-pattern */
    }

    thead {
        [data-theme='vi'] & {
            background: rgb(var(--background));
        }
    }
}
