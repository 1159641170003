@use 'mixins';

.MatchHeader {
    position: relative;
    padding: var(--largePadding);
    z-index: 0;
    color: rgb(var(--invertedTextColor));
    border-radius: var(--standardRadius) var(--standardRadius) 0 0;

    [data-theme='vi'] & {
        padding-top: var(--hugePadding);
        background-color: rgb(var(--lighterInvertedBackground));
    }

    &::after,
    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        content: '';
    }

    .background {
        width: 80%;
        padding-left: 20%;
        background-color: rgb(var(--invertedBackground));
        box-sizing: content-box;
        filter: grayscale(80%);
        object-position: top right;
        object-fit: cover;
    }

    &::after {
        // FIXME: Find a way to server svg from /web/public folder work
        background-image: url('https://vp.cdn.pxr.nl/images/vp/bg/tag-gradient.svg');
        background-position: top right;
        background-size: 100% 100%;

        [data-theme='vi'] & {
            display: none;
        }
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .match-details {
        display: flex;
        align-items: flex-start;
    }

    .recent-form {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;

        [data-theme='vi'] & {
            a {
                color: rgb(var(--invertedTextColor));
            }

            &.away {
                [class*='TeamElement_logo'] {
                    margin: 0 var(--standardPadding) 0 0;
                }
            }

            &.home {
                [class*='TeamElement_logo'] {
                    margin: 0 0 0 var(--standardPadding);
                }
            }
        }
    }

    :global(.ScoreElement) {
        margin: 0 var(--standardPadding);
        font-size: 2em;

        [data-theme='vi'] & {
            margin: 0 var(--largePadding);
            font-size: var(--heading-medium-font-size);

            li {
                background-color: rgb(var(--secondaryTextColor));
                color: rgb(var(--invertedTextColor));
            }
        }
    }

    .match-score {
        display: flex;
        flex-direction: column;
    }

    .match-time {
        margin-bottom: var(--tinyPadding);
        text-align: center;

        [data-theme='vi'] & {
            margin-top: var(--standardPadding);
            margin-bottom: var(--smallPadding);
            font-family: var(--text-font-family);
            color: rgb(var(--lighterTextColor));
        }
    }

    .expected-goals-text {
        [data-theme='vi'] & {
            @extend %paragraph-medium;

            color: rgb(var(--lighterTextColor));
        }
    }

    .expected-goals-tag {
        @extend %tag-medium;

        margin-left: var(--smallPadding);
        color: rgb(var(--invertedTextColor));
    }

    .match-odds {
        flex-direction: column;

        hr {
            margin: var(--standardPadding);
            background-color: rgb(var(--background));

            [data-theme='vi'] & {
                background-color: transparent;
            }
        }
    }

    :global(.stop-gambling) {
        margin: var(--smallPadding) auto 0;
    }
}

@include mixins.responsive(m, below) {
    .MatchHeader {
        :global(.ScoreElement) {
            font-size: 1.85em;
        }
    }
}
